import Image from 'next/image';
import { useContext, useEffect, useState } from 'react';
import { STORAGE_KEYS } from '../../utils/config';
import { PopupSignupProps } from '../../@types/popup';
import { toast } from 'react-hot-toast';
import FormOrder from '../PopupOrder/FormOrder';
import { IOrderType } from '../../@types/IProductList';
import AppContext from '../../store/AppContext';

const PopupSignup: React.FC<PopupSignupProps> = ({
    id,
    is_product,
    setOpen,
    additionalFunc
}: any) => {
    const [width, setWidth] = useState(0);
    const { branchs } = useContext(AppContext);
    const [selectedTask, setSelectedTask] = useState(0);
    const [email, setEmail] = useState(localStorage.getItem(STORAGE_KEYS.email) ?? "");
    const [name, setName] = useState(localStorage.getItem(STORAGE_KEYS.name) ?? "");
    const [phone, setPhone] = useState(localStorage.getItem(STORAGE_KEYS.phone) ?? "");
    const isWorking = false;
    let task = [
        {
            id: 0,
            label: 'Đăng ký người dùng',
        },
        {
            id: 1,
            label: 'Đặt lịch thử đồ',
        }
    ]

    function onSubmit() {
        localStorage.setItem(STORAGE_KEYS.email, email);
        localStorage.setItem(STORAGE_KEYS.name, name);
        localStorage.setItem(STORAGE_KEYS.phone, phone);
        //setOpen(false);
        //additionalFunc();
        if (name && phone) {
            if (width < 700) {
                setOpen(false);
                additionalFunc();
                return;
            }
            onChangeStep(1);
        } else {
            toast.error('Vui lòng nhập tên hoặc số điện thoại')
        }
    }

    function onChangeStep(step: number) {
        if (name && phone) {
            setSelectedTask(step);
        } else {
            toast.error('Vui lòng nhập tên hoặc số điện thoại')
        }
    }

    const registerForm = () => (
        <>
            <div className='flex-1 flex flex-col w-[100vw] md:w-full overflow-hidden  items-center pb-14 pt-4 px-2 lg:pt-[4rem] gap-4'>
                <div className='border-[1px] border-[#D9D9D9]  w-full lg:w-[456px] py-[7px] px-4 flex flex-col gap-1 rounded-lg'>
                    <span className='text-[12px] text-[#8C8C8C] font-base'>Họ và tên</span>
                    <input defaultValue={localStorage.getItem(STORAGE_KEYS.name) ?? ""} type="text" onChange={(e: any) => {
                        setName(e.target.value)
                    }} className='focus:outline-none w-full h-[1.5rem] text-[#8C8C8C] border-none px-0' />
                </div>
                <div className='border-[1px] border-[#D9D9D9] w-full lg:w-[456px] py-[7px] px-4 flex flex-col gap-1 rounded-lg'>
                    <span className='text-[12px] text-[#8C8C8C] font-base'>Số điện thoại</span>
                    <input defaultValue={localStorage.getItem(STORAGE_KEYS.phone) ?? ""} type="text" onChange={(e: any) => {
                        setPhone(e.target.value)
                    }} className='focus:outline-none w-full h-[1.5rem] text-[#8C8C8C] border-none px-0' />
                </div>
                <div className='border-[1px] border-[#D9D9D9] w-full lg:w-[456px] py-[7px] px-4 flex flex-col gap-1 rounded-lg'>
                    <span className='text-[12px] text-[#8C8C8C] font-base'>Email</span>
                    <input defaultValue={localStorage.getItem(STORAGE_KEYS.email) ?? ""} type="email" onChange={(e: any) => {
                        setEmail(e.target.value)
                    }} className='focus:outline-none w-full h-[1.5rem] text-[#8C8C8C] border-none px-0' />
                </div>
            </div>
            <div className='flex w-[100vw] md:w-full items-center justify-center pb-6 flex-row gap-3'>
                <button onClick={() => setOpen(false)} className='bg-[#fff] border-[1px] border-[#434343] rounded-md text-[#434343] text-sm py-2 w-[159px]'>Hủy</button>
                <button onClick={onSubmit} className='bg-[#1890FF] rounded-md text-white text-sm py-2 w-[159px]'>Tiếp</button>
            </div>
        </>
    );

    useEffect(() => {
        setWidth(window.innerWidth);
    }, [])

    return <div key={"pop_up_sign_up"} id="map_popup" className="wrap_popup">
            { isWorking ? 
                <div className="order_pop w-[1046px] overflow-hidden flex flex-col lg:w-[50%] w-full lg:w-h-[70vh] rounded-t-[16px] lg:rounded-[16px] z-[15] overflow-hidden fixed lg:relative lg:bottom-[0px] bottom-[60px] my-0 lg:my-[70px]">
                    <div className="flex flex-row w-[100vw] md:w-full p-6 justify-between items-center popup_sign_up_header">
                        <div className="flex flex-col">
                            <span className="text-sm text-[#434343] font-semibold">{task[selectedTask].label}</span>
                        </div>
                        <div className='flex flex-row items-center'>
                            <div
                                onClick={() => onChangeStep(0)}
                                className={`
                                rounded-full 
                                cursor-pointer
                                ${selectedTask === 0 ? 'bg-blue-500' : 'bg-white'}
                                ${selectedTask === 0 ? 'text-white' : 'text-[#262626]'}
                                shadow 
                                h-8 w-8 
                                flex 
                                items-center 
                                justify-center`}>
                                1
                            </div>
                            <div className='h-[1px] w-6 bg-[#8c8c8c]'></div>
                            <div
                                onClick={() => onChangeStep(1)}
                                className={`
                                rounded-full 
                                cursor-pointer
                            ${selectedTask === 1 ? 'bg-blue-500' : 'bg-white'}
                            ${selectedTask === 1 ? 'text-white' : 'text-[#262626]'}
                                shadow 
                                h-8 w-8 
                                flex 
                                items-center 
                                justify-center`}>
                                2
                            </div>
                            <div className="ml-6 cursor-pointer text-lg" onClick={() => { setOpen(false) }}>x</div>
                        </div>
                    </div>
                    {selectedTask === 0 ? <>
                        {registerForm()}
                    </> :
                        <FormOrder
                            is_product={is_product}
                            isOthers={false}
                            orderType={IOrderType.orderCalendar}
                            isOpen={true}
                            setIsOpen={setOpen}
                            id={id}
                            orderDate={{
                                takeDate: null,
                                returnDate: null
                            }} branchs={branchs} />
                    }
                </div>
             : <div className="bg-noti-footer">
             Chức năng đang hoàn thiện, quý khách vui lòng liên hệ qua thông tin chi nhánh. <br/>Mong quý khách thông cảm, xin cảm ơn!
             <div className="icon-close" onClick={() => { setOpen(false) }}>x</div>
            </div>
            }
        
    </div>
}

export default PopupSignup;